//import { useState } from "react";
import "./contact.scss";
import { toast } from "react-toastify";
import "./toastify.css";
import Social from "../social/Social";
import {Row, Col} from "react-bootstrap"

toast.configure();

export default function Contact() {
  //const [message, setMessage] = useState(false);

  const handleSubmit = (e) => {
    let form = e.target;
    let _fromEmail = form["0"].value;
    let _message = form["1"].value;

    if (validateEmailAddress(_fromEmail)) {
      sendMail(_message, _fromEmail);
      form["0"].value = "";
      form["1"].value = "";
    } else {
      //alert("please share your email with me :)")
      toast("Please enter a valid email address");
    }

    e.preventDefault();
  };

  const sendMail = (message, replyTo) => {
    const templateID = "template_wfbijj6";
    const variables = {
      message_html: message,
      from_name: "Prtfolio Website",
      reply_to: replyTo,
    };
    toast.promise(
      window.emailjs.send("service_39iftpp", templateID, variables), //.then(res => {
      // console.log('Email successfully sent!')
      //toast.success("I Will get back to you as soon as possible ☺️")
      //setMessage(true);
      //})
      // Handle errors here however you like, or use a React error boundary
      //.catch(err => console.error('Oh well, Email Send  failed. Here some thoughts on the error that occured:', err)
      {
        pending: "Telling Prasun About You 🥳",
        success: "Prasun Got Your Message 🥰",
        error: "Ahh!💩 Prasun Missed Your Message, Can You Try Again?",
      }
    );
  };

  const validateEmailAddress = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <div className="contact section-item" id="contact">
      <div className="left">
        <img src="assets/shake.svg" alt="" />
      </div>
      <div className="right">
        <h2>Let's Talk</h2>
        <form onSubmit={handleSubmit}>

          <Row>
            <Col sm={12}>
            <input type="text" placeholder="Email" />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
            <textarea placeholder="Message"></textarea>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
            <button type="submit">Send</button>
            </Col>
          </Row>

          
          
          
          {/* {message && <span>
            <h4>Thank you for getting in touch!</h4>
            I Will get back to you as soon as possible <span className="big">☺️</span>
            </span>} */}
        </form>
        <div>
          <span>or connectn with me on</span>
        </div>
        <Social></Social>
      </div>
    </div>
  );
}
