import Topbar from "./components/topbar/Topbar";
import Intro from "./components/intro/Intro";
import Portfolio from "./components/portfolio/Portfolio";
import Works from "./components/works/Works";
// import Testimonials from "./components/testimonials/Testimonials"
import Contact from "./components/contact/Contact";
import "./app.scss";
import { useState } from "react";
import Menu from "./components/menu/Menu";
import { Helmet } from "react-helmet";
import Projects from "./components/projects/Projects";
import Certifications from "./components/certifications/Certifications";
import Footer from "./components/footer/Footer";

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div>
      <div className="app">
        <Helmet>
          <meta name="description" content="Prasun Guchhait portfolio" />
          <meta
            name="keywords"
            content="Prasun, Guchhait, prasunguchhait1997@gmail.com, portfolio, prasun guchhait"
          />
        </Helmet>
        <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        <div className="sections">
          <Intro />
          <Portfolio />
          <Works />
          {/* <Testimonials/> */}
          <Projects />
          <Certifications />
          <Contact />
          <div className="footer-section">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
