import "./topbar.scss";
import resume from "../../assets/Resume.pdf";

export default function Topbar({ menuOpen, setMenuOpen }) {
  return (
    <div className={"topbar " + (menuOpen && "active")}>
      <div className="wrapper">
        <div className="left">
          <a href="#intro" className="logo">
            PG
          </a>
          {/* <div className="itemContainer">
            <span>+44 924 12 74</span>
          </div>
          <div className="itemContainer">
            <span>safak@genius.com</span>
          </div> */}
        </div>
        <div className="right">
          <div className="bar-menu">
            <a href="#intro" className="header-menu">Home</a>
            <a href="#portfolio" className="header-menu">About</a>
            <a href="#works" className="header-menu">Skills</a>
            <a href="#projects" className="header-menu">Projects</a>
            <a href="#certifications" className="header-menu">Certifications</a>
            {/* <a href="#testimonials" className="header-menu">Testimonials</a> */}
            <a href="#contact" className="header-menu">Contact</a>
            <a
            href={resume}
            download="Resume_Prasun_Guchhait.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="header-menu"
          >
            Resume
          </a>
          </div>
          <div className="menu-button">
            <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
              <span className="line1"></span>
              <span className="line2"></span>
              <span className="line3"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
