import "./intro.scss";
import { init } from "ityped";
import { useEffect, useRef } from "react";

export default function Intro() {
  const textRef = useRef();
  const welcome = useRef();

  useEffect(() => {
    init(textRef.current, {
      showCursor: true,
      backDelay: 1500,
      backSpeed: 60,
      strings: [
        "Learner",
        "Developer",
        "Designer",
        "Engineer",
        "Tech Enthusiast",
        "Indian",
      ],
    });
  }, []);

  useEffect(() => {
    init(welcome.current, {
      showCursor: true,
      backDelay: 1500,
      backSpeed: 60,
      strings: [
        "नमस्ते",
        "Hello",
        "নমস্কর",
        "Bonjour",
        "Hola",
        "Salve",
        "Guten Tag",
      ],
    });
  }, []);

  return (
    <div className="intro section-item" id="intro">
      <div className="left">
        <div className="imgContainer mobile-container">
          {/* <img src="assets/man.png" alt="" /> */}
          <h1 className="bigText">
            <span className="welcome-span" ref={welcome}></span>
          </h1>
        </div>
      </div>
      <div className="right">
        <div className="wrapper">
          <h2>I'm </h2>
          <h1>Prasun Guchhait</h1>
          <h3>
            <span ref={textRef}></span>
          </h3>
          <a href="#contact">
            <button>Say Hello</button>
          </a>
          {/* <button className="helloButton">
            <a href="#works">Works</a>
          </button> */}
        </div>
        <a className="arrow" href="#portfolio">
          <img src="assets/down.png" alt="" />
        </a>
      </div>
    </div>
  );
}
