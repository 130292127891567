export const featuredPortfolio = [
  {
    id: 1,
    title: "Microsoft .NET",
    img: "https://cdn.jsdelivr.net/npm/simple-icons@4.15.0/icons/dot-net.svg",
  },
  {
    id: 2,
    title: "C sharp",
    img: "https://cdn.jsdelivr.net/npm/simple-icons@4.15.0/icons/csharp.svg",
  },
  {
    id: 3,
    title: "javaScript",
    img: "https://cdn.jsdelivr.net/npm/simple-icons@4.15.0/icons/javascript.svg",
  },
  {
    id: 4,
    title: "Typescript",
    img: "https://cdn.jsdelivr.net/npm/simple-icons@4.15.0/icons/typescript.svg",
  },
  {
    id: 5,
    title: "Angular",
    img: "https://cdn.jsdelivr.net/npm/simple-icons@4.15.0/icons/angular.svg",
  },
  {
    id: 6,
    title: "Html5",
    img: "https://cdn.jsdelivr.net/npm/simple-icons@4.15.0/icons/html5.svg",
  },
  {
    id: 7,
    title: "CSS3",
    img: "https://cdn.jsdelivr.net/npm/simple-icons@4.15.0/icons/css3.svg",
  },
  {
    id: 8,
    title: "git",
    img: "https://cdn.jsdelivr.net/npm/simple-icons@4.15.0/icons/git.svg",
  },
  {
    id: 9,
    title: "Bootstrap",
    img: "https://cdn.jsdelivr.net/npm/simple-icons@4.15.0/icons/bootstrap.svg",
  },
  {
    id: 10,
    title: "Docker",
    img: "https://cdn.jsdelivr.net/npm/simple-icons@4.15.0/icons/docker.svg",
  },
  {
    id: 11,
    title: "jQuery",
    img: "https://cdn.jsdelivr.net/npm/simple-icons@4.15.0/icons/jquery.svg",
  },
  {
    id: 12,
    title: "Node",
    img: "https://cdn.jsdelivr.net/npm/simple-icons@4.15.0/icons/node-dot-js.svg",
  },
  {
    id: 13,
    title: "React",
    img: "https://cdn.jsdelivr.net/npm/simple-icons@4.15.0/icons/react.svg",
  },
  {
    id: 14,
    title: "NPM",
    img: "https://cdn.jsdelivr.net/npm/simple-icons@4.15.0/icons/npm.svg",
  },
  {
    id: 15,
    title: "SQL Server",
    img: "https://cdn.jsdelivr.net/npm/simple-icons@4.15.0/icons/microsoftsqlserver.svg",
  },
  {
    id: 16,
    title: "Microsoft .Net Core",
    img: "assets/NET_Core_Logo.svg",
  },
  {
    id: 17,
    title: "Microsoft Azure",
    img: "assets/azure.svg",
  },
  {
    id: 18,
    title: "Visual Studio",
    img: "https://cdn.jsdelivr.net/npm/simple-icons@4.15.0/icons/visualstudio.svg",
  },
  {
    id: 18,
    title: "VS Code",
    img: "https://cdn.jsdelivr.net/npm/simple-icons@4.15.0/icons/visualstudiocode.svg",
  },
  {
    id: 19,
    title: "Jenkins",
    img: "https://cdn.jsdelivr.net/npm/simple-icons@4.15.0/icons/jenkins.svg",
  },
  {
    id: 20,
    title: "Python",
    img: "https://cdn.jsdelivr.net/npm/simple-icons@4.15.0/icons/python.svg",
  },
  {
    id: 21,
    title: "NumPy",
    img: "https://cdn.jsdelivr.net/npm/simple-icons@4.15.0/icons/numpy.svg",
  },
  {
    id: 22,
    title: "XUnit",
    img: "https://xunit.net/images/full-logo.svg",
  },
];

export const certifications = [
  {
    id: 1,
    title: "Advance RPA Developer",
    authority: "Automation Anywhere", 
    img: "assets/automation-anywhere certificate.svg",
    badgeURL: "https://certificates.automationanywhere.com/1c59b8a6-780e-4885-a5cc-ccc781675b9a"
  },
  {
    id: 2,
    title: "Azure Fundamentals",
    authority: "Microsoft", 
    img: "https://images.credly.com/size/680x680/images/be8fcaeb-c769-4858-b567-ffaaa73ce8cf/image.png",
    badgeURL: "https://www.credly.com/badges/5337e2aa-c76a-44f8-82a1-55065abe51b8/public_url"
  },
  {
    id: 3,
    title: "Azure Data Fundamentals",
    authority: "Microsoft", 
    img: "https://images.credly.com/size/680x680/images/70eb1e3f-d4de-4377-a062-b20fb29594ea/azure-data-fundamentals-600x600.png",
    badgeURL: "https://www.credly.com/badges/3f7afc5a-ee14-4464-8ad9-4e06b935ae26/public_url"
  },
  {
    id: 4,
    title: "Azure Developer Associate",
    authority: "Microsoft", 
    img: "https://images.credly.com/size/680x680/images/63316b60-f62d-4e51-aacc-c23cb850089c/azure-developer-associate-600x600.png",
    badgeURL: "https://www.credly.com/badges/94dfa704-a899-4b35-a384-f550f88d3be5/public_url"
  },
  {
    id: 5,
    title: "Security, Compliance, and Identity Fundamentals",
    authority: "Microsoft", 
    img: "https://images.credly.com/images/fc1352af-87fa-4947-ba54-398a0e63322e/security-compliance-and-identity-fundamentals-600x600.png",
    badgeURL: "https://www.credly.com/badges/d93cf0af-adf6-4e14-ab40-42f8e017e808/public_url"
  },
];

export const projects = [
  {
    id: 1,
    name: "E-Commerce Application",
    title: "Anugular 11 | Material | TypeScript",
    desc: "",
    appLink: "https://ecommerceapp.prasunguchhait.com",
    gitLink: "https://github.com/guchhaitprasun/e-commerce-application",
    imgUrl: "assets/ecommerceapp.png",
  },
  {
    id: 2,
    name: "Portfolio Website",
    title: "React | Bootstrap",
    desc: "",
    featured: true,
    appLink: "https://prasunguchhait.com",
    gitLink: "https://github.com/guchhaitprasun/portfolio-website",
    imgUrl: "assets/portfolio.png",
  },
  {
    id: 3,
    name: "Weathe App",
    title: "Angular 13 | Typescript",
    desc: "",
    appLink: "https://www.weather.prasunguchhait.com",
    gitLink: "https://github.com/guchhaitprasun/WeatherApp",
    imgUrl: "assets/weather.png",
  },
];

export const testimonials = [
  {
    id: 1,
    name: "Dharmender Gurjar",
    title: "Software Development Engineer",
    img: "https://media-exp1.licdn.com/dms/image/C4E03AQHz4LDz0vO6Ww/profile-displayphoto-shrink_100_100/0/1570508667099?e=1648684800&v=beta&t=vB_AzzVKrr971ngQIJfo55HYT2eOgv9fB4EPYYRZfWI",
    icon: "assets/linkedin.png",
    desc: "His work ethics and technical knowledge are pristine, and he is easily adjustable to a given situation. His ability to go out of his way to help others has made him stand out.",
  },
  {
    id: 2,
    name: "Disha Chaudhary",
    title: "Full Stack Web Developer",
    img: "https://media-exp1.licdn.com/dms/image/C5103AQH0_C6DFw-w-Q/profile-displayphoto-shrink_100_100/0/1577727730679?e=1648684800&v=beta&t=yacVL9PaJE1aeuaY7le_RW9_n2SMbKqhXJONeAgm2nk",
    icon: "assets/linkedin.png",
    desc: `Prasun's biggest strength is his ability to deal with conflicting priorities in high-pressure situations. He never loses his cool which is one of the reasons he stands out.”`,
    featured: true,
  },
  {
    id: 3,
    name: "Uttkarsh Goyal",
    title: "Software Development Engineer",
    img: "https://media-exp1.licdn.com/dms/image/C4E03AQHEoHUkI9s71g/profile-displayphoto-shrink_100_100/0/1624961832503?e=1648684800&v=beta&t=m6nze5Eup2ODgnuqiZ8ise-Lh0NISHKmHtlKcMvcOy4",
    icon: "assets/linkedin.png",
    desc: "Prasun is self motivated and always try to provides intuitive solutions having good knowledge of MVC .Net and JavaScript",
  },
];

export const contact = [
  {
    id: 1, 
    name: "gitHub", 
    icon: "assets/contacts/github-icon.svg", 
    url: "https://github.com/guchhaitprasun"
  }, 
  {
    id: 2, 
    name: "LinkedIn", 
    icon: "assets/contacts/linkedin.svg", 
    url: "https://www.linkedin.com/in/iamprasunguchhait"
  }, 
  {
    id: 3, 
    name: "Facebook", 
    icon: "assets/contacts/facebook-icon.svg", 
    url: "https://www.facebook.com/bubu.prasun.861997.guchhait"
  },
  {
    id: 4, 
    name: "Instagram", 
    icon: "assets/contacts/instagram.svg", 
    url: "https://www.instagram.com/iamprasunguchhait"
  }, 
  {
    id: 5, 
    name: "Twitter", 
    icon: "assets/contacts/twitter.svg", 
    url: "https://twitter.com/guchhaitprasun"
  }, 
  {
    id: 6, 
    name: "Email", 
    icon: "assets/contacts/gmail-icon.svg", 
    url: "mailto:prasunguchhait1997@gmail.com"
  },
  {
    id: 7, 
    name: "Google", 
    icon: "assets/contacts/google-icon.svg", 
    url: "https://www.google.com/search?q=prasun+guchhait"
  }
]