import { useEffect, useState } from "react";
import "./works.scss";
import {
  featuredPortfolio
} from "../../data";

export default function Works() {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(featuredPortfolio);
  }, []);

  return (
    <div className="works section-item" id="works">
      <h1>Tools <span className="ampersent">&</span> Skills</h1>
      {/* <ul>
        {list.map((item) => (
          <PortfolioList
            title={item.title}
            active={selected === item.id}
            setSelected={setSelected}
            id={item.id}
          />
        ))}
      </ul> */}
      <div className="container">
        {data.map((d) => (
          <div className="item">
            <img
              src={d.img}
              alt=""
            />
            <h3>{d.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}
