import { useEffect, useState } from "react";
import "./project.scss";
import {projects} from "../../data"

export default function Projects() {

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(projects);
  }, []);

  return (
    <div className="testimonials section-item" id="projects">
      <h1>Personal Projects</h1>
      <div className="container">
        {data.map((d) => (
          <div className={d.featured ? "card featured" : "card"} style={{
            backgroundImage: `linear-gradient(to bottom, rgba(255,255,255,0.8) 0%,rgba(255,255,255,0.8) 100%), url(${d.imgUrl})`,
            backgroundSize: 'cover', 
            backgroundRepeat: 'no-repeat',
          }}>
            <div className="top">
              <a
                href={d.gitLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <img src="assets/git.svg" className="left" alt="" />{" "}
              </a>
              &nbsp;&nbsp;&nbsp;
              <a
                href={d.appLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <img src="assets/right-arrow.png" className="left" alt="" />
              </a>

              
            </div>
            {/* <div className="center">{d.desc}</div> */}
            <div className="bottom">
              <h3>{d.name}</h3>
              <h5 style={{ color: "grey" }}>{d.title}</h5>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
