import "./menu.scss";
import resume from "../../assets/Resume.pdf";

export default function Menu({ menuOpen, setMenuOpen }) {
  return (
    <div className={"menu " + (menuOpen && "active")}>
      <ul>
        <li onClick={() => setMenuOpen(false)}>
          <a href="#intro">Home</a>
        </li>
        <li onClick={() => setMenuOpen(false)}>
          <a href="#portfolio">About</a>
        </li>
        <li onClick={() => setMenuOpen(false)}>
          <a href="#works">Skills</a>
        </li>
        <li onClick={() => setMenuOpen(false)}>
          <a href="#projects">Projects</a>
        </li>
        <li onClick={() => setMenuOpen(false)}>
          <a href="#certifications">Certifications</a>
        </li>
        {/* <li onClick={() => setMenuOpen(false)}>
          <a href="#testimonials">Testimonials</a>
        </li> */}
        <li onClick={() => setMenuOpen(false)}>
          <a href="#contact">Contact</a>
        </li>
        <li onClick={() => setMenuOpen(false)}>
          <a
            href={resume}
            download="Resume_Prasun_Guchhait.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Resume
          </a>
        </li>
      </ul>
    </div>
  );
}
