import { useEffect, useState } from "react";
import { contact } from "../../data";
import "./social.scss"

const Social = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(contact);
  }, []);

  return (
    <div className="contact-container">
      {data.map((d) => (
        <div className="item">
          <a href={d.url} target="_blank" rel="noopener noreferrer">
            <img src={d.icon} alt="" />
          </a>
        </div>
      ))}
    </div>
  );
};

export default Social;
