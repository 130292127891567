import "./portfolio.scss";
import resume from "../../assets/Resume.pdf";
import Social from "../social/Social";

export default function Portfolio() {
  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  let age = getAge("1997/06/08");

  return (
    <div className="portfolio section-item" id="portfolio">
      {/* <h1>About</h1> */}
      <div className="container">
        <div className="item">
          {/* <img src="assets/man.png" alt="" /> */}
          <img src="assets/20200714_224417.jpg" alt="" />
          <h3>Nice to meet you 👋</h3>
        </div>
      </div>
      <div className="description first">
        Hi I am Prasun Guchhait, a {age} year old Software Engineer, Web
        developer, and a web designer living in Jaipur, India. I am a Computer
        Science Engineer, Graduated from Manipal University Jaipur, currently
        working with awsome folks at Nagarro.
      </div>
      <div className="description para">
        I'm quietly confident, naturally curious, and perpetually working on
        improving my skills one at a time. I'm all about combining visual design
        with product thinking to bring meaningful experiences alive.
      </div>
      <div className="description para resume">
        <a
          href={resume}
          download="Resume_Prasun_Guchhait.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="assets/file_download_black_24dp.svg" alt="" />
        </a>
        <a
          href={resume}
          download="Resume_Prasun_Guchhait.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>Download my resume</span>
          <br />
        </a>
      </div>

      <Social></Social>
    </div>
  );
}
