import { useEffect, useState } from "react";
import "./certifications.scss";
import { certifications } from "../../data";

export default function Works() {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(certifications);
  }, []);

  return (
    <div className="certifications section-item" id="certifications">
      <h1>Certifications</h1>
      <div className="container">
        {data.map((d) => (
          <div className="card">
            <div className="left">
              <img src={d.img} alt="" />
            </div>
            <div className="right">
              <h4>{d.title}</h4>
              <span className="authority">{d.authority}</span>
              <a href={d.badgeURL} target="_blank" rel="noopener noreferrer">
                <span>
                  verify &nbsp;
                  <img src="assets/right-arrow.png" alt="" />
                </span>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
