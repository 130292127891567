import "./footer.scss";

export default function Footer() {
  return (
    <div className="footer">
      {/* <div className="left">
        <p>
          <b className="big-text">No &#169; copyright issues.</b>
          <br />
          Feel free to copy. If you need any help, ping me !
        </p>
      </div>
      <div className="middle">
        <p class="madewithlove">
          Made with <span className="red">&#10084;</span> in India
        </p>
      </div>
      <div className="right">right</div> */}

      <div className="middle">
        <p class="madewithlove">
          Made with <span className="red">&nbsp;&#10084;&nbsp;</span> in India
        </p>
      </div>
    </div>
  );
}
